<template>
	<div>
		<div class="content">
			<el-card class="myMenu_box">
				<div v-for="(item, index) in myMenu" :key="index" class="myMenu">
					<div class="titlt">{{ item.name }}</div>
					<router-link v-for="(child, index) in item.children" :key="index" class="list" :to="child.path">{{ child.name }}</router-link>
				</div>
			</el-card>
			<el-card class="content_box"><router-view></router-view></el-card>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OrderCenter',
	components: {},
	data() {
		return {
			myMenu: [
				{
					name: '订单中心',
					path: '',
					children: [
						{
							name: '我的订单',
							path: '/order/list'
						},
					]
				},
				{
					name: '个人中心',
					path: '',
					children: [
						{
							name: '我的账户',
							path: '/order/account'
						},
						{
							name: '认证中心',
							path: '/order/list'
						},
						{
							name: '收件地址',
							path: '/order/addressList'
						},
						{
							name: '优惠券',
							path: '/order/list'
						},
						{
							name: '消息通知',
							path: '/order/list'
						},
						{
							name: '移动端',
							path: '/order/iphone'
						}
					]
				}
			]
		};
	},
	methods: {}
};
</script>

<style lang="less" scoped>
.content {
	width: 1232px;
	margin: 16px auto 16px auto;
	display: flex;
	justify-content: space-between;
	.myMenu_box {
		width: 156px;
		height: 100%;
		margin-right: 16px;
		.myMenu {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;
			.titlt {
				font-size: 14px;
				font-weight: 700;
				line-height: 40px;
			}
			a {
				line-height: 40px;
				height: 40px;
				font-size: 12px;
			}
			a:hover {
				color: #f60;
			}
		}
	}
	.content_box {
		flex: 1;
	}
}
</style>
